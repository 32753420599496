import _ from 'lodash'
import moment from 'moment'
import { TUserGender } from './constants'
import {
  Bending,
  EnduranceRun,
  FitnessTest,
  Grip,
  HandballThrow,
  ShuttleRun,
  SideJump,
  SitUps,
  SprintRun,
  StandingJump,
} from './fitnessTest'
import { TTestResultWithPoint, computeTotalPoint } from './points'
import { checkIfIsValidRank } from './rank'
import { TTestRes } from './types/api/res'

// export const gradesForSchoolCategoryCode = (code: string) => {
//   switch (code) {
//     case 'B1':
//       return 6
//     case 'C1':
//       return 3
//     case 'C2':
//       return 9
//     case 'D1':
//       return 4
//     case 'D2':
//       return 6
//     default:
//       return 3
//   }
// }

export const allTestKeys = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'enduranceRun',
  'shuttleRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
] as const

const refineTestData = (testResult) => {
  if (testResult) {
    const {
      bending,
      enduranceRun,
      grip,
      handballThrow,
      shuttleRun,
      sideJump,
      sitUps,
      sprintRun,
      sizeTest,
      standingJump,
    } = testResult

    if (grip?.gripLeft1) grip.gripLeft1 = Number(grip.gripLeft1)
    if (grip?.gripLeft2) grip.gripLeft2 = Number(grip.gripLeft2)
    if (grip?.gripRight1) grip.gripRight1 = Number(grip.gripRight1)
    if (grip?.gripRight2) grip.gripRight2 = Number(grip.gripRight2)
    if (sitUps?.sitUps) sitUps.sitUps = Number(sitUps.sitUps)
    if (bending?.bending1) bending.bending1 = Number(bending.bending1)
    if (bending?.bending2) bending.bending2 = Number(bending.bending2)
    if (enduranceRun?.runningTime)
      enduranceRun.runningTime = Number(enduranceRun.runningTime)
    if (enduranceRun?.runningTimeSeconds)
      enduranceRun.runningTimeSeconds = Number(enduranceRun.runningTimeSeconds)
    if (handballThrow?.handballThrow1)
      handballThrow.handballThrow1 = Number(handballThrow.handballThrow1)
    if (handballThrow?.handballThrow2)
      handballThrow.handballThrow2 = Number(handballThrow.handballThrow2)
    if (shuttleRun?.shuttleRunCount)
      shuttleRun.shuttleRunCount = Number(shuttleRun.shuttleRunCount)
    if (sideJump?.sideJump1) sideJump.sideJump1 = Number(sideJump.sideJump1)
    if (sideJump?.sideJump2) sideJump.sideJump2 = Number(sideJump.sideJump2)
    if (sizeTest?.height) sizeTest.height = Number(sizeTest.height)
    if (sizeTest?.weight) sizeTest.weight = Number(sizeTest.weight)
    if (sprintRun?.sprintRunSeconds)
      sprintRun.sprintRunSeconds = Number(sprintRun.sprintRunSeconds)
    if (standingJump?.standingJump1)
      standingJump.standingJump1 = Number(standingJump.standingJump1)
    if (standingJump?.standingJump2)
      standingJump.standingJump2 = Number(standingJump.standingJump2)
  }
}

export const reCalcTestResult = (
  testResult: TTestRes | undefined,
  age: number,
  gender: TUserGender | undefined | null,
  hasEnduranceRun: boolean,
): TTestResultWithPoint => {
  if (!testResult) {
    return {} as TTestResultWithPoint
  }

  const _testResult = _.cloneDeep(testResult) as TTestResultWithPoint
  const userGender = (gender?.toUpperCase() ?? 'MALE') as TUserGender

  refineTestData(_testResult)
  for (const key of allTestKeys) {
    const res = _testResult[key]

    if (res) {
      if (res.isNotMeasurable) {
        res.value = 0
        res.points = 0
        res.rating = 0
        res.memo = ''

        if (key === 'enduranceRun') {
          res.runningTime = 0
          res.runningTimeSeconds = 0
        }
      } else if (
        key === 'enduranceRun' &&
        res.runningTime !== null &&
        res.runningTimeSeconds !== null
      ) {
        const runningTimeSeconds = moment
          .duration({
            minute: res.runningTime,
            second: res.runningTimeSeconds,
          })
          .asSeconds()

        res.value = EnduranceRun.computeValue(runningTimeSeconds)
        res.points = EnduranceRun.computePoints(
          runningTimeSeconds,
          age,
          userGender,
        )

        res.rating = EnduranceRun.getRating(runningTimeSeconds, age, userGender)
      }
      //  else if (key === 'sitUps' && res.sitUps == null) {
      //   res.value = undefined
      //   res.points = undefined
      // }

      if (res.points && res.points < 10) {
        res.nextPoints = computeNextPointValue(
          key,
          res.value,
          res.points,
          age,
          userGender,
        )
      }
    }
  }

  _testResult.points = computeTotalPoint(hasEnduranceRun, _testResult)

  if (checkIfIsValidRank(_testResult, hasEnduranceRun)) {
    _testResult.rank = FitnessTest.computeRank(
      _testResult.points,
      Number.isNaN(age) ? 6 : age,
    )
  } else {
    _testResult.rank = ''
  }

  return _testResult
}

/**
 * Not for sprint or endurance running!
 *
 * @param point - always smaller than 10
 */
const getNextPointValue = (
  ranges: Record<string, { Range: number[] }>,
  value: number | undefined | null,
  point: number,
): number | undefined => {
  const currentRange = ranges[point + 1]

  if (currentRange) {
    // @ts-ignore
    return currentRange.Range[0] - value
  }
}

export const roundToOneDecimal = (value: number): number =>
  Math.round(value * 10) / 10

/**
 * For sprint running!
 *
 * @param point - always smaller than 10
 */
const getNextPointValueForSprintRunning = (
  ranges: Record<string, { Range: number[] }>,
  value: number | undefined | null,
  point: number,
): number | undefined => {
  const currentRange = ranges[point]

  if (currentRange) {
    // @ts-ignore
    const nextLevelLack = value - currentRange.Range[0]

    if (nextLevelLack !== 0) {
      const lackPoint = roundToOneDecimal(nextLevelLack + 0.1)
      return lackPoint
    }

    return 0.1
  }
}

/**
 * For endurance running!
 *
 * @param point - always smaller than 10
 */
const getNextPointValueForEnduranceRunning = (
  ranges: Record<string, { Range: number[] }>,
  value: number | null | undefined,
  point: number,
): number | undefined => {
  const currentRange = ranges[point]

  if (currentRange) {
    // @ts-ignore
    const nextLevelLack = value - currentRange.Range[0]

    if (nextLevelLack !== 0) {
      const lackPoint = nextLevelLack + 1
      return lackPoint
    }

    return 1
  }
}

/**
 *
 * @param type
 * @param value
 * @param point - always smaller than 10
 * @param age
 * @param gender
 * @returns
 */
const computeNextPointValue = (
  type: string,
  value: number | undefined | null,
  point: number,
  age: number,
  gender: TUserGender,
): number | undefined => {
  switch (type) {
    case 'grip': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = Grip.elementarySchoolRanges.MALE
        } else {
          ranges = Grip.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = Grip.elementarySchoolRanges.FEMALE
        } else {
          ranges = Grip.middleSchoolRanges.FEMALE
        }
      }

      return getNextPointValue(ranges, value, point)
    }
    case 'sitUps': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = SitUps.elementarySchoolRanges.MALE
        } else {
          ranges = SitUps.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = SitUps.elementarySchoolRanges.FEMALE
        } else {
          ranges = SitUps.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value, point)
    }

    case 'bending': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = Bending.elementarySchoolRanges.MALE
        } else {
          ranges = Bending.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = Bending.elementarySchoolRanges.FEMALE
        } else {
          ranges = Bending.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value, point)
    }

    case 'sideJump': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = SideJump.elementarySchoolRanges.MALE
        } else {
          ranges = SideJump.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = SideJump.elementarySchoolRanges.FEMALE
        } else {
          ranges = SideJump.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value, point)
    }

    case 'enduranceRun': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = EnduranceRun.middleSchoolRanges.MALE
        } else {
          ranges = EnduranceRun.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = EnduranceRun.middleSchoolRanges.FEMALE
        } else {
          ranges = EnduranceRun.middleSchoolRanges.FEMALE
        }
      }

      return getNextPointValueForEnduranceRunning(ranges, value, point)
    }

    case 'shuttleRun': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = ShuttleRun.elementarySchoolRanges.MALE
        } else {
          ranges = ShuttleRun.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = ShuttleRun.elementarySchoolRanges.FEMALE
        } else {
          ranges = ShuttleRun.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value, point)
    }

    // 50m
    case 'sprintRun': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = SprintRun.elementarySchoolRanges.MALE
        } else {
          ranges = SprintRun.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = SprintRun.elementarySchoolRanges.FEMALE
        } else {
          ranges = SprintRun.middleSchoolRanges.FEMALE
        }
      }

      return getNextPointValueForSprintRunning(ranges, value, point)
    }

    case 'standingJump': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = StandingJump.elementarySchoolRanges.MALE
        } else {
          ranges = StandingJump.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = StandingJump.elementarySchoolRanges.FEMALE
        } else {
          ranges = StandingJump.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value, point)
    }
    case 'handballThrow': {
      let ranges: Record<string, { Range: number[] }>
      if (gender === 'MALE') {
        if (age < 12) {
          ranges = HandballThrow.elementarySchoolRanges.MALE
        } else {
          ranges = HandballThrow.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = HandballThrow.elementarySchoolRanges.FEMALE
        } else {
          ranges = HandballThrow.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value, point)
    }
  }
}

export const computeNextGradeValue = (
  grade: string,
  points: number,
  age: number,
) => {
  if (age < 6) {
    age = 6
  } else if (age > 19) {
    age = 19
  }
  const ranges = FitnessTest.ranges[age]
  const nextGrade = String.fromCharCode(grade.charCodeAt(0) - 1)
  const nextGradeRanges = ranges[nextGrade]
  if (nextGradeRanges) {
    return { grade: nextGrade, points: nextGradeRanges.Range[0] - points }
  }
}

export function ageFromSchool(
  schoolCategoryCode: string,
  grade: number,
): number {
  return grade + initialAgeForSchoolCategoryCode(schoolCategoryCode) - 1
}

export const initialAgeForSchoolCategoryCode = (code: string): number => {
  switch (code) {
    case 'B1':
      return 6
    case 'C1':
      return 12
    case 'C2':
      return 6
    case 'D1':
      return 15
    case 'D2':
      return 12
    default:
      return 6
  }
}

/**
 * Junior high school or high school
 *
 * 小学校: 'B1',
 * 中学校: 'C1',
 * 高等学校: 'D1',
 * 義務教育学校: 'C2',
 * 中等教育学校: 'D2',
 *
 * @param schoolCategoryCode
 * @returns
 */
export function isHighOrJuniorHighSchool(schoolCategoryCode: string): boolean {
  return ['C1', 'D1'].includes(schoolCategoryCode)
}

/**
 * Translated in translation.json of school.
 */
export const schoolCategoryJpNameCode = {
  B1: '小学校',
  C1: '中学校',
  D1: '高等学校',
  C2: '義務教育学校',
  D2: '中等教育学校',
} as const

/**
 * Junior high school and high school can input shuttle run
 * and endurance run at the same time.
 * 中高のみシャトルランと持久走を両方入力ができるようにする
 *
 * @param school - school attributes
 * @returns
 */
export function schoolHasEnduranceTest(
  school: undefined | { schoolCategoryCode: string },
): boolean {
  if (school === undefined) return false
  return isHighOrJuniorHighSchool(school.schoolCategoryCode)
}

export function parseTeacherType(v: string) {
  if (v === 'SCHOOL_ADMIN') {
    return '全体管理者'
  } else if (v === 'GRADE_ADMIN') {
    return '学年管理者'
  } else {
    return '担任'
  }
}

export function convertJapNumbers(input: string): string {
  const chars = {
    '０': '0',
    '１': '1',
    '２': '2',
    '３': '3',
    '４': '4',
    '５': '5',
    '６': '6',
    '７': '7',
    '８': '8',
    '９': '9',
  }
  return input.replace(/[０１２３４５６７８９]/g, (m) => chars[m])
}

export const createBlankArr = (length: number): number[] => {
  return Array.from({ length: length }, (_, i) => i + 1)
}

export * from './rank'
