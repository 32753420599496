import { ReactNode } from 'react'
import { useAlphaStore } from '~/context'

type Props = {
  children: ReactNode
  className?: string
}

export const Rt = ({ children, className }: Props) => {
  const { schoolCategoryCode } = useAlphaStore()

  return (
    schoolCategoryCode === 'B1' && <rt className={className}>{children}</rt>
  )
}
